@import '~styles/global/utilities';

.font-primary {
  font-family: $font-family-franklin-condensed;
}

.font-secondary {
  font-family: $font-family-franklin;
}

.font-tertiary {
  font-family: $font-family-freight;
}

.text-xs {
  font-size: 12.64px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 11.06px;
  }
}

.text-xs-no-break-point {
  font-size: 12.64px;
  line-height: $line-height-text;
}

.text-sm {
  font-size: 14.22px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 12.44px;
  }
}

.text-sm-no-break-point {
  font-size: 14.22px;
}

.text-base {
  font-size: 16px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 14px;
  }
}

.text-base-no-break-point {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 15.75px;
  }
}

.text-xl {
  font-size: 20.25px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 17.72px;
  }
}

.text-2xl {
  font-size: 22.78px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 19.93px;
  }
}

.text-3xl {
  font-size: 25.63px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 22.43px;
  }
}

.text-4xl {
  font-size: 28.83px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 25.23px;
  }
}

.text-5xl {
  font-size: 32.44px;
  line-height: $line-height-text;
  @include breakpoint-max(lg) {
    font-size: 28.38px;
  }
}

.color-faded-70 {
  opacity: 0.7;
}

.color-faded-50 {
  opacity: 0.5;
}

.color-primary {
  color: color(twilight-blue);
}

.color-secondary {
  color: color(white);
}

.color-tertiary {
  color: color(yellow);
}

.bg-color-primary {
  background-color: color(twilight-blue);
}

.bg-color-secondary {
  background-color: color(yellow);
}

.bg-color-tertiary {
  background-color: color(white);
}

.bg-color-disabled {
  background-color: color(grayish-blue);
}

.letter-spacing {
  letter-spacing: 1.6px; // figma is in 10% but browser doesn't like % values, this was converted using this converter https://www.unitsconverters.com/en/Percent-To-Em
}

.line-height-text {
  line-height: $line-height-text;
}

.line-height-body {
  line-height: $line-height-body;
}

.line-height-paragraph {
  line-height: $line-height-paragraph;
}

// Text Component Styles

.primary-text-preset {
  @extend .font-primary;
  @include fw--600;
}

.primary-text-preset-1 {
  @extend .primary-text-preset;
  @extend .text-5xl;
}

.primary-text-preset-2 {
  @extend .primary-text-preset;
  @extend .text-4xl;
}

.primary-text-preset-3 {
  @extend .primary-text-preset;
  @extend .text-3xl;
}

.primary-text-preset-4 {
  @extend .primary-text-preset;
  @extend .text-2xl;
}

.primary-text-preset-5 {
  @extend .primary-text-preset;
  @extend .text-xl;
}

.primary-text-preset-6 {
  @extend .primary-text-preset;
  @extend .text-lg;
}

.primary-text-preset-7 {
  @extend .primary-text-preset;
  @extend .text-base;
}

.primary-text-preset-8 {
  @extend .primary-text-preset;
  @extend .text-sm;
}

.primary-text-preset-9 {
  @extend .primary-text-preset;
  @extend .text-xs;
}

.secondary-text-preset-1 {
  @extend .font-secondary;
  @extend .text-lg;
}

.secondary-text-preset-2 {
  @extend .font-secondary;
  @extend .text-base;
}

.secondary-text-preset-3 {
  @extend .font-secondary;
  @extend .text-sm;
}

.secondary-text-preset-4 {
  @extend .font-secondary;
  @extend .text-xs;
}

.tertiary-text-preset {
  @extend .font-tertiary;
  @extend .color-faded-70;
}

.tertiary-text-preset-1 {
  @extend .tertiary-text-preset;
  @extend .text-base-no-break-point;
  @extend .text-base;
  line-height: $line-height-body;
}

.tertiary-text-preset-2 {
  @extend .tertiary-text-preset;
  @extend .text-sm;
}

%primary-button-and-link-preset {
  @extend .font-primary;
  @extend .uppercase-new;
  @extend .letter-spacing;
  @extend .text-sm-no-break-point;
  @extend .line-height-text;
  @include fw--600;
  cursor: pointer;
}

.brand-preset {
  @extend .uppercase-new;
  @extend .color-faded-70;
  transition: opacity 100ms ease-in;
  &:hover {
    color: color(twilight-blue);
    opacity: 1;
  }
}

.badge-preset-base {
  @extend .font-secondary;
  @extend .text-xs;
  @extend .uppercase-new;
  @include fw--500;

  line-height: $line-height-text;
  padding: 6px 8px 6px 8px;
  text-align: center;

  @include breakpoint-max(sm) {
    padding: 4px 6px 4px 6px;
  }
}

.badge-preset-2 {
  @extend.badge-preset-base;
  @extend .color-primary;
  background-color: color(gray-bg);
}

.badge-preset-3 {
  @extend.badge-preset-base;
  @extend .color-secondary;
  @extend .bg-color-primary;
}

.primary-input-label {
  @extend .font-primary;
  @extend .text-base;
  @extend .text-base-no-break-point;
  @include fw--400;
}

.primary-input-preset-1 {
  @extend .font-primary;
  @extend .text-base;

  box-sizing: border-box;
  border: 1px solid color(gray-bg);
  background: #fbfbfb;
  height: 100%;
  padding: 12px;

  &::placeholder {
    @extend .color-primary;
    @extend .color-faded-70;
    @include fw--400;
  }

  &:focus {
    border: 1px solid color(twilight-blue-faded);
  }
}

.input-preset--error {
  border-color: color(red);
  &:focus {
    border-color: color(red);
  }
}

.underline {
  text-decoration: underline;
}

// this is to support hover states for input
.hover-blue,
.hover-blue-faded-70 {
  &:hover {
    color: color(twilight-blue);
  }
}

.hover-blue {
  &:hover {
    opacity: 1;
  }
}

.hover-blue-faded-70 {
  &:hover {
    opacity: 0.7;
  }
}
