$sides: (
  t: top,
  b: bottom,
  l: left,
  r: right
);

$alignments: (
  l: left,
  r: right,
  c: center
);

///
// === Text Color ===
//

@each $color-name, $color-value in $all-colors {
  .color--#{inspect($color-name)} {
    color: $color-value;
  }
}

@each $color-name, $color-value in $all-colors {
  .color--#{inspect($color-name)}--hover:hover {
    color: $color-value;
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @each $color-name, $color-value in $all-colors {
      .color--#{inspect($color-name)}--#{$breakpoint-name} {
        color: $color-value;
      }
    }
  }
}

//
// === Background ===
//

@each $color-name, $color-value in $all-colors {
  .bg--#{inspect($color-name)} {
    background: $color-value;
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @each $color-name, $color-value in $all-colors {
      .bg--#{inspect($color-name)}--#{$breakpoint-name} {
        background: $color-value;
      }
    }
  }
}

//
// === Border Color ===
//

@each $color-name, $color-value in $all-colors {
  .b--#{inspect($color-name)} {
    border: 1px solid $color-value;
  }
}

@each $color-name, $color-value in $all-colors {
  @each $side-name, $side-direction in $sides {
    .b#{inspect($side-name)}--#{inspect($color-name)} {
      border-#{$side-direction}: 1px solid $color-value;
    }
  }
}

@each $color-name, $color-value in $all-colors {
  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media (min-width: #{$breakpoint-size}) {
      .b--#{inspect($color-name)}--#{$breakpoint-name} {
        border: 1px solid $color-value;
      }
    }
  }
}

@each $color-name, $color-value in $all-colors {
  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media (min-width: #{$breakpoint-size}) {
      @each $side-name, $side-direction in $sides {
        .b#{inspect($side-name)}--#{inspect($color-name)}--#{inspect($breakpoint-name)} {
          border-#{$side-direction}: 1px solid $color-value;
        }
      }
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .b--none--#{$breakpoint-name} {
      border: none;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @each $side-name, $side-direction in $sides {
      .b#{inspect($side-name)}--#{inspect($breakpoint-name)} {
        border-#{$side-direction}: none;
      }
    }
  }
}

//
// === Text Align ===
//

@each $alignment-name, $alignment-direction in $alignments {
  .t#{$alignment-name} {
    text-align: #{$alignment-direction};
  }
}

@each $alignment-name, $alignment-direction in $alignments {
  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media (min-width: #{$breakpoint-size}) {
      .t#{$alignment-name}--#{$breakpoint-name} {
        text-align: #{$alignment-direction};
      }
    }
  }
}

///
// === Font Weight ===
//

@mixin fw--400 {
  font-weight: 400;
}
@mixin fw--500 {
  font-weight: 500;
}
@mixin fw--600 {
  font-weight: 600;
}

.fw--400 {
  @include fw--400;
}

.fw--500 {
  @include fw--500;
}

.fw--600 {
  @include fw--600;
}

//
// === Display ===
//

.db {
  display: block !important;
}
.dn {
  display: none !important;
}
.di {
  display: inline !important;
}
.dib {
  display: inline-block !important;
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .db--#{$breakpoint-name} {
      display: block !important;
    }
    .dn--#{$breakpoint-name} {
      display: none !important;
    }
    .di--#{$breakpoint-name} {
      display: inline !important;
    }
    .dib--#{$breakpoint-name} {
      display: inline-block !important;
    }
  }
}

//
// === Position ===
//

.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.ps {
  position: static;
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .pr--#{$breakpoint-name} {
      position: relative;
    }
    .pa--#{$breakpoint-name} {
      position: absolute;
    }
    .ps--#{$breakpoint-name} {
      position: static;
    }
  }
}

.pa--flush {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

//
// === Full size blocks ===
//

.full-width {
  display: block;
  width: 100%;
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .full-width--#{$breakpoint-name} {
      display: block;
      width: 100%;
    }
  }
}

.full-height {
  display: block;
  height: 100%;
}

.full-height-no-db {
  height: 100%;
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .full-height--#{$breakpoint-name} {
      display: block;
      height: 100%;
    }
  }
}

//
// === Alignment ===
//

.va__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

//
// === Opacity ===
//

$opacities: (
  0: 0,
  10: 0.1,
  20: 0.2,
  30: 0.3,
  40: 0.4,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8,
  90: 0.9,
  100: 1
);

@each $opacity-name, $opacity-value in $opacities {
  .o#{inspect($opacity-name)} {
    opacity: $opacity-value;
  }

  .o#{inspect($opacity-name)}--hover {
    &:hover {
      opacity: $opacity-value;
    }
  }

  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media (min-width: #{$breakpoint-size}) {
      .o#{inspect($opacity-name)}--#{$breakpoint-name} {
        opacity: $opacity-value;
      }

      .o#{inspect($opacity-name)}--hover--#{$breakpoint-name} {
        &:hover {
          opacity: $opacity-value;
        }
      }
    }
  }
}

//
// === Whitespace ===
//

.ws--pre {
  white-space: pre;
}

.ws--nowrap {
  white-space: nowrap;
}

.ws--normal {
  white-space: normal;
}

.wb--break-word {
  word-break: break-word;
}

//
// === Pointer Events ===
//

.pe--none {
  pointer-events: none;
}

.pe--auto {
  pointer-events: auto;
}

//
// === Margin ===
//

.m--auto {
  margin-left: auto;
  margin-right: auto;
}

//
// === Text Transform ===
//

.tt--u {
  text-transform: uppercase;
}

.tt--l {
  text-transform: lowercase;
}

.tt--c {
  text-transform: capitalize;
}

// new uppercase with no letter spacing, to replace old uppercase
.uppercase-new {
  text-transform: uppercase;
}

//
// === Prevent Scroll ===
//

.prevent-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

//
// === Display flex w/ options
//

.df {
  display: flex;
}

.flex-wrap--nowrap {
  flex-wrap: nowrap;
}

.flex-wrap--wrap {
  flex-wrap: wrap;
}

.flex-wrap--wrap-reverse {
  flex-wrap: wrap-reverse;
}

$flex-direction-opts: (
  c: column,
  cr: column-reverse,
  r: row,
  rr: row-reverse
);
@each $flex-direction-name, $flex-direction in $flex-direction-opts {
  .flex-direction--#{$flex-direction-name} {
    flex-direction: #{$flex-direction};
  }
}

$align-items-opts: (
  b: baseline,
  c: center,
  s: stretch,
  fs: flex-start,
  fe: flex-end
);
@each $align-items-name, $align-items in $align-items-opts {
  .align-items--#{$align-items-name} {
    align-items: #{$align-items};
  }
}

$justify-content-opts: (
  c: center,
  st: start,
  e: end,
  fs: flex-start,
  fe: flex-end,
  s: stretch,
  se: space-evenly,
  sb: space-between,
  sa: space-around
);
@each $justify-content-name, $justify-content in $justify-content-opts {
  .justify-content--#{$justify-content-name} {
    justify-content: #{$justify-content};
  }
}

$gap-max: 24;
$gap-size: 4px;
@for $i from 0 through $gap-max {
  .gap#{$i} {
    gap: $i * $gap-size;
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @for $i from 0 through $gap-max {
      .gap#{$i}--#{$breakpoint-name} {
        gap: $i * $gap-size;
      }
    }
  }
}
